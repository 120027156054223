import { dev } from "$app/environment";
import { PUBLIC_SENTRY_ENVIRONMENT } from "$env/static/public";
import { handleErrorWithSentry } from "@sentry/sveltekit";
import * as Sentry from "@sentry/sveltekit";

let currentHandleError = null;

if (!dev) {
    Sentry.init({
        dsn: "https://df6ccaff9740c7682e919539964bb464@svr-sv7-sentry1.sailx.co/5",
        tracesSampleRate: 1.0,
        environment: PUBLIC_SENTRY_ENVIRONMENT
    });

    currentHandleError = handleErrorWithSentry();
}

export const handleError = currentHandleError;
